import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Frontpage from "./components/frontpage";
import WhyNudge from "./components/why-nudge";
import ClimateNudge from "./components/climate-nudge";
import SustainabilityNudge from "./components/sustainability-nudge";
import HealthNudge from "./components/health-nudge";
import MentalHealthNudge from "./components/mental-health-nudge";
import NotFound from "./components/not-found";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Frontpage />} />
        <Route exact path="/hvorfor-nudge" element={<WhyNudge />} />
        <Route exact path="/klimanudge" element={<ClimateNudge />} />
        <Route exact path="/bærekraftnudge" element={<SustainabilityNudge />} />
        <Route exact path="/sunnhetsnudge" element={<HealthNudge />} />
        <Route
          exact
          path="/mental-helse-nudge"
          element={<MentalHealthNudge />}
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
