import {
  ContentWrapper,
  CreditsLabel,
  FooterLabel,
  Section,
  FooterLink,
  FooterContent,
  Logo,
  BottomLogoWrap,
  BottomContent,
} from "./styled";
import { Link } from "react-router-dom";
import klimapsykologeneLogo from "../../assets/images/klimapsykologene.svg";
import unitedNationsLogo from "../../assets/images/united-nations.png";

const Index = () => {
  return (
    <Section>
      <ContentWrapper>
        <BottomContent>
          <BottomLogoWrap>
            <Link to="https://www.klimapsykologene.no/">
              <Logo src={klimapsykologeneLogo} />
            </Link>
            <Link to="https://unglobalcompact.org/">
              <Logo src={unitedNationsLogo} />
            </Link>
          </BottomLogoWrap>
          <FooterContent>
            <FooterLabel>
              E-post:{" "}
              <FooterLink href="mailto:post@nudge.no">post@nudge.no</FooterLink>
            </FooterLabel>
            <FooterLabel>
              Mobil:{" "}
              <FooterLink href="tel:+47 917 07 075">+47 917 07 075</FooterLink>
            </FooterLabel>
          </FooterContent>
          <CreditsLabel>
            Utvikling og design av{" "}
            <Link to="https://www.linkedin.com/in/andreasskaalerud/">
              Andreas Skaalerud
            </Link>
          </CreditsLabel>
        </BottomContent>
      </ContentWrapper>
    </Section>
  );
};

export default Index;
