import styled from "@emotion/styled";
import { Col, Row, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Section = styled.section`
  position: relative;
  background-color: #fff;
`;

export const ContentWrapper = styled.div`
  padding: 30px 50px 20px 50px;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
`;

export const FooterRow = styled(Row)``;

export const FooterCol = styled(Col)`
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 25px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const FooterTitle = styled.h5`
  font-family: "Poppins", sans-serif;
  color: grey;
`;

export const FooterContent = styled.div`
  margin-top: 15px;
`;

export const FooterLabel = styled.span`
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  display: block;
  line-height: 1.8rem;
  font-size: 16px;
`;

export const FooterLink = styled.a`
  color: #5a9572 !important;
`;

export const CreditsLabel = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: grey;
  font-size: 14px;
  margin: 0 auto;
  display: block;
  text-align: center;
  margin-top: 35px;
  a {
    color: #5a9572;
  }
`;

export const LogoLink = styled(Link)`
  text-decoration: none;
`;

export const NavigationBrand = styled(Navbar.Brand)`
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  cursor: pointer;
  color: #5a9572;
  font-weight: 500;
  svg {
    width: 90px;
    height: 50px;
    margin-top: -5px;
    margin-right: 5px;
  }
  &:hover {
    color: #5a9572;
  }
`;

export const SocialIcon = styled.img`
  height: 45px;
`;

export const Logo = styled.img`
  display: block;
  width: 100%;
  max-width: 175px;
  margin-left: -10px;
  margin-top: 5px;
  &:first-of-type {
    margin-right: 10px;
  }
`;

export const BottomLogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;

export const BottomContent = styled.div`
  text-align: center;
`;
