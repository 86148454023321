import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  position: relative;
  background-color: #fff;
`;

export const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  &.margin-top {
    margin-top: 175px;
  }
  &.focus {
    background: #ededed;
    padding-top: 75px;
    padding-bottom: 75px;
  }
  @media (max-width: 767px) {
    &.margin-top {
      margin-top: 100px;
    }
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1300px;
  position: relative;
  margin: 0 auto;
  padding: 0px 15px;
  &.align-center {
    text-align: center;
  }
`;

export const UpperContentWrapper = styled.div`
  margin-top: 100px;
`;

export const ContentRow = styled(Row)``;

export const ContentCol = styled(Col)``;

export const Title = styled.h1`
  color: #aa7e56;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 48px;
  @media (max-width: 767px) {
    font-size: 36px;
    &.below-picture {
      margin-top: 25px;
    }
  }
`;

export const Text = styled.p`
  margin-top: 15px;
  line-height: 2rem;
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
`;

export const Thumbnail = styled.img`
  width: 100%;
  padding: 10px;
  &.hero {
    background-color: #fff;
    border: 5px solid #fff;
    box-shadow: 0px 0px 20px #dcdcdc;
  }
  &.hero-fade {
    padding: 40px;
  }
`;

export const MiddleContentWrapper = styled.div``;

export const MiddleContentRow = styled(Row)``;

export const MiddleContentCol = styled(Col)``;

export const BottomContentWrapper = styled.div``;

export const ProjectsRow = styled(Row)`
  margin-top: 15px;
`;

export const ProjectCol = styled(Col)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const ProjectWrapper = styled.div`
  background-color: #fff;
  padding: 20px;
`;

export const ProjectThumbnail = styled.img`
  width: 100%;
  min-height: 175px;
  object-fit: contain;
  padding: 40px;
  margin-bottom: 20px;
  @media (max-width: 1160px) {
    padding: 20px;
    min-height: none;
    max-height: 75px;
  }
`;

export const ProjectTitle = styled.h3`
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
`;

export const ProjectDescription = styled.p`
  margin-top: 15px;
  line-height: 2rem;
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
`;

export const DownloadLink = styled.a`
  text-decoration: none;
`;

export const DownloadButton = styled.button`
  width: 100%;
  border: none;
  font-family: "Poppins", sans-serif;
  background-color: #5a9572 !important;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  font-weight: 500;
  color: #fff;
`;

export const DownloadIcon = styled.img`
  width: 22.5px;
  margin-right: 10px;
`;

export const Button = styled.button`
  background-color: #5a9572;
  font-family: "Poppins", sans-serif;
  border: none;
  color: #fff;
  padding: 12.5px 15px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
`;

export const VideoWrapper = styled.div`
  margin-top: 125px;
`;
