import {
  Button,
  Container,
  ContentWrapper,
  Section,
  Text,
  Title,
} from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <ContentWrapper className="align-center">
          <Title>Siden eksisterer ikke</Title>
          <Text>
            Siden du prøver å gå til har enten blitt flyttet eller eksisterer
            ikke lenger.
          </Text>
          <Link to="/">
            <Button>Gå til forsiden</Button>
          </Link>
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
