import {
  ContentWrapper,
  Section,
  Title,
  Text,
  ContentCol,
  ContentRow,
  Thumbnail,
  ProjectsRow,
  ProjectCol,
  ProjectWrapper,
  ProjectThumbnail,
  ProjectTitle,
  ProjectDescription,
  Container,
  Button,
} from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import topThumbnail from "../../assets/images/sustainability-top-thumbnail.jpeg";
import compassGroupLogo from "../../assets/images/compass-group-logo.svg";
import ministerLogo from "../../assets/images/ministerraad-logo.svg";
import vattenfallLogo from "../../assets/images/vattenfall-logo.svg";
import uioLogo from "../../assets/images/uio-logo.svg";
import { Link } from "react-router-dom";

const Index = () => {
  const ALL_PROJECTS = [
    {
      logo: ministerLogo,
      title: "Nordisk Ministerråd",
      description:
        "Vi anbefalte hvordan nudge til sunnere transportvalg og mer aktiv livsstil.",
      url: "",
      type: "report",
    },
    {
      logo: compassGroupLogo,
      title: "Compass Group",
      description:
        "Vi kom med råd om hvordan bompenger og andre nudge kan påvirke transportvalg.",
      url: "",
      type: "report",
    },
    {
      logo: vattenfallLogo,
      title: "Vattenfall",
      description:
        "Vi ga råd om hvordan man kan påvirke til aksept for bærekraftig energiutbygging.",
      url: "",
      type: "keynote",
    },
    {
      logo: uioLogo,
      title: "Universitetet i Oslo",
      description:
        "Vi analyserte hvordan energiselskap gjør bærekraftige valg om fabrikker i norsk natur.",
      url: "",
      type: "keynote",
    },
  ];

  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <ContentWrapper>
          <ContentRow>
            <ContentCol
              xs={{ span: 12, order: 2 }}
              md={{ span: 7, order: 1 }}
              className="my-auto"
            >
              <Title className="below-picture">Bærekraft</Title>
              <Text>
                Hvordan stimulere til bærekraftige valg?
                <br />
                <br />
                Mange er opptatt av bærekraft, men opplever det som vanskelig i
                praksis. Vi vet hvordan påvirke til bærekraftig mat, transport
                og energi.
              </Text>
            </ContentCol>
            <ContentCol xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
              <Thumbnail className="hero" src={topThumbnail} />
            </ContentCol>
          </ContentRow>
        </ContentWrapper>
      </Container>
      <Container className="focus">
        <ContentWrapper>
          <Title>Prosjekter</Title>
          <ProjectsRow>
            {ALL_PROJECTS.map((project) => (
              <ProjectCol md={3}>
                <ProjectWrapper>
                  <ProjectThumbnail src={project.logo} />
                  <ProjectTitle>{project.title}</ProjectTitle>
                  <ProjectDescription>{project.description}</ProjectDescription>
                </ProjectWrapper>
              </ProjectCol>
            ))}
          </ProjectsRow>
        </ContentWrapper>
      </Container>
      <Container>
        <ContentWrapper className="align-center">
          <Title>Interessert i å vite mer?</Title>
          <Text>
            Få tilsendt et uforpliktende tilbud om nudge til din virksomhet.
          </Text>
          <Link to="mailto:knut.ivar@klimapsykologene.no">
            <Button>Få tilbud om nudge</Button>
          </Link>
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
