import styled from "@emotion/styled";

export const Section = styled.section`
  position: relative;
  background-color: #fff;
`;

export const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  &.margin-top {
    margin-top: 175px;
  }
  @media (max-width: 767px) {
    &.margin-top {
      margin-top: 100px;
    }
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1300px;
  position: relative;
  margin: 0 auto;
  padding: 100px 15px;
  &.align-center {
    text-align: center;
  }
`;

export const Title = styled.h1`
  color: #aa7e56;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 48px;
  @media (max-width: 767px) {
    font-size: 36px;
    &.below-picture {
      margin-top: 25px;
    }
  }
`;

export const Text = styled.p`
  margin-top: 15px;
  line-height: 2rem;
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
`;

export const Button = styled.button`
  background-color: #5a9572;
  font-family: "Poppins", sans-serif;
  border: none;
  color: #fff;
  padding: 12.5px 15px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
`;
