import {
  Container,
  ContentCol,
  ContentRow,
  ContentWrapper,
  Section,
  Text,
  Thumbnail,
  Title,
  ResearchWrapper,
  SingleItem,
  ItemThumbnailCol,
  ItemDetailsCol,
  DetailsWrapper,
  ItemTitle,
  ItemDescription,
  Button,
  ProjectsRow,
  ProjectCol,
  ProjectWrapper,
  ProjectThumbnail,
  ProjectTitle,
  ProjectDescription,
} from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import topThumbnail from "../../assets/images/health-nudge-thumbnail.jpg";
import nbimUnicefLogo from "../../assets/images/unicef-nbim.svg";
import norgesgruppenLogo from "../../assets/images/norway-groceries.svg";
import nbimLogo from "../../assets/images/nbim.svg";
import ministerraadLogo from "../../assets/images/ministerraad-logo.svg";
import { Link } from "react-router-dom";

const Index = () => {
  const LIST_ITEMS = [
    {
      logo: nbimLogo,
      title: "Oljefondet NBIM",
      description:
        "Hvordan påvirke til mer bærekraftige matvalg? Vi oppsummerte og presenterte den nyeste forskningen.",
    },
    {
      logo: nbimUnicefLogo,
      title: "UNICEF og Oljefondet",
      description:
        "Hvordan påvirke til mødre til sunnere mat til barna sine?  Vi ga innspill til UNICEF og Oljefondets anbefalinger til hvordan den globale matbransjen kan fremme sunnere valg.",
    },
    {
      logo: norgesgruppenLogo,
      title: "Den norske dagligvarevarebransjen",
      description:
        "Hvordan nudge forbrukerne til å spise sunnere mat? Vi analyserte kunnskapsgrunnlaget for sunnhetsnudge og utformet praktiske råd til norsk matbransje.",
    },
    {
      logo: ministerraadLogo,
      title: "Nordisk Ministerråd",
      description:
        "Hvordan påvirke folks helsevalg? Hvordan nudge sunnere livsstil gjennom mat, mindre alkoholforbruk og fysisk aktivitet?",
    },
  ];

  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <ContentWrapper>
          <ContentRow>
            <ContentCol
              xs={{ span: 12, order: 2 }}
              md={{ span: 7, order: 1 }}
              className="my-auto"
            >
              <Title className="below-picture">Sunnhet</Title>
              <Text>
                De fleste vet hva som er sunnest å spise, men mange velger
                annerledes slik at vekten øker og helsen svikter. Dårlig
                kosthold og sviktende helse som følge av overvekt er blant de
                største helseproblemene i hele verden. Hvordan kan
                dagligvarebutikken og jobbkantina påvirke folk til sunnere valg?
                Hvordan påvirke folk til å bevege seg mer og trene mer? Vi har
                gjennomført flere nudgeprosjekter om dette.
              </Text>
            </ContentCol>
            <ContentCol xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
              <Thumbnail className="hero" src={topThumbnail} />
            </ContentCol>
          </ContentRow>
        </ContentWrapper>
      </Container>
      <Container className="focus">
        <ContentWrapper>
          <Title>Prosjekter</Title>
          <ProjectsRow>
            {LIST_ITEMS.map((project) => (
              <ProjectCol md={3}>
                <ProjectWrapper>
                  <ProjectThumbnail src={project.logo} />
                  <ProjectTitle>{project.title}</ProjectTitle>
                  <ProjectDescription>{project.description}</ProjectDescription>
                </ProjectWrapper>
              </ProjectCol>
            ))}
          </ProjectsRow>
        </ContentWrapper>
      </Container>
      <Container>
        <ContentWrapper className="align-center">
          <Title>Interessert i å vite mer?</Title>
          <Text>
            Få tilsendt et uforpliktende tilbud om nudge til din virksomhet.
          </Text>
          <Link to="mailto:knut.ivar@klimapsykologene.no">
            <Button>Få tilbud om nudge</Button>
          </Link>
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
