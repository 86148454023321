import {
  ContentWrapper,
  Section,
  Title,
  Text,
  Thumbnail,
  ResearchWrapper,
  SingleItem,
  ItemDetailsCol,
  ItemTitle,
  ItemDescription,
  DetailsWrapper,
  Container,
  ContentRow,
  ContentCol,
  ItemThumbnailCol,
  Button,
  ProjectsRow,
  ProjectCol,
  ProjectWrapper,
  ProjectThumbnail,
  ProjectTitle,
  ProjectDescription,
} from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import topThumbnail from "../../assets/images/behavioural-economics.svg";
import orklaFoodsLogo from "../../assets/images/orkla-logo.svg";
import remaTusenLogo from "../../assets/images/rema-logo.svg";
import strawberryLogo from "../../assets/images/strawberry-logo.svg";
import vegvesenLogo from "../../assets/images/statens-vegvesen-logo.svg";
import { Link } from "react-router-dom";

const Index = () => {
  const LIST_ITEMS = [
    {
      logo: strawberryLogo,
      title: "Strawberry Hotels",
      description:
        "Vi hjalp Nordic Choice Hotels med å påvirke gjestene til å spise mer fisk og sjømat. ",
    },
    {
      logo: orklaFoodsLogo,
      title: "Orkla Foods",
      description:
        "Vi formidlet den nyeste nudgeforskningen og anbefalte tips til mer klimavennlige tiltak.",
    },
    {
      logo: remaTusenLogo,
      title: "Rema 1000",
      description:
        "Vi analyserte hvordan utformingen av butikkene økte salget av fisk og sjømat.",
    },
    {
      logo: vegvesenLogo,
      title: "Statens Vegvesen",
      description:
        "Vi kom med råd om hvordan bompenger og andre nudge kan påvirke transportvalg.",
    },
  ];

  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <ContentWrapper>
          <ContentRow>
            <ContentCol
              xs={{ span: 12, order: 2 }}
              md={{ span: 9, order: 1 }}
              className="my-auto"
            >
              <Title className="below-picture">Klima</Title>
              <Text>
                Hvordan påvirke til klimavennlige valg?
                <br />
                <br /> Snart kommer boken om hvordan påvirke folk til å velge
                sunt, klimavennlig og bærekraftig. Boken forklarer hvilke nudge
                som gir størst effekt og har mange praktiske eksempler. Les
                denne artikkelen som oppsummerer boken:{" "}
                <Link to="https://www.behavioraleconomics.com/be-guide/the-behavioral-economics-guide-2023/">
                  https://www.behavioraleconomics.com/be-guide/the-behavioral-economics-guide-2023/
                </Link>
              </Text>
            </ContentCol>
            <ContentCol
              xs={{ span: 12, order: 1 }}
              md={{ span: 3, order: 2 }}
              className="my-auto"
            >
              <Thumbnail className="hero" src={topThumbnail} />
            </ContentCol>
          </ContentRow>
        </ContentWrapper>
      </Container>
      <Container className="focus">
        <ContentWrapper>
          <Title>Prosjekter</Title>
          <ProjectsRow>
            {LIST_ITEMS.map((project) => (
              <ProjectCol md={3}>
                <ProjectWrapper>
                  <ProjectThumbnail src={project.logo} />
                  <ProjectTitle>{project.title}</ProjectTitle>
                  <ProjectDescription>{project.description}</ProjectDescription>
                </ProjectWrapper>
              </ProjectCol>
            ))}
          </ProjectsRow>
        </ContentWrapper>
      </Container>
      {false && (
        <Container>
          <ContentWrapper>
            <Title>Prosjekter</Title>
            <ResearchWrapper>
              {LIST_ITEMS.map((item, index, arr) => (
                <SingleItem className={index === arr.length - 1 ? "last" : ""}>
                  <ItemThumbnailCol>
                    <Thumbnail className="logo" src={item.logo} />
                  </ItemThumbnailCol>
                  <ItemDetailsCol>
                    <DetailsWrapper>
                      <ItemTitle>{item.title}</ItemTitle>
                      <ItemDescription>{item.description}</ItemDescription>
                    </DetailsWrapper>
                  </ItemDetailsCol>
                </SingleItem>
              ))}
            </ResearchWrapper>
          </ContentWrapper>
        </Container>
      )}
      <Container>
        <ContentWrapper className="align-center">
          <Title>Interessert i å vite mer?</Title>
          <Text>
            Få tilsendt et uforpliktende tilbud om nudge til din virksomhet.
          </Text>
          <Link to="mailto:knut.ivar@klimapsykologene.no">
            <Button>Få tilbud om nudge</Button>
          </Link>
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
