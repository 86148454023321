import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  position: relative;
  background-color: #fff;
`;

export const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  &.margin-top {
    margin-top: 175px;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1300px;
  position: relative;
  margin: 0 auto;
  padding: 0px 15px;
`;

export const ContentRow = styled(Row)``;

export const ContentCol = styled(Col)``;

export const Title = styled.h1`
  color: #aa7e56;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 48px;
`;

export const Text = styled.p`
  margin-top: 15px;
  line-height: 2rem;
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
`;

export const Thumbnail = styled.img`
  width: 100%;
  padding: 10px;
  &.hero {
    border: 5px solid #fff;
    box-shadow: 0px 0px 20px #dcdcdc;
  }
`;
