import {
  ContentWrapper,
  Section,
  Text,
  Thumbnail,
  Title,
  ContentCol,
  ContentRow,
  Container,
  TagsWrapper,
  Tag,
  DownloadButton,
  DownloadIcon,
  Button,
} from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import mentalHealthTopThumbnail from "../../assets/images/mental-health-top-thumbnail.jpg";
import feliceJackaThumbnail from "../../assets/images/felice-jacka-thumbnail.jpeg";
import reportThumbnail from "../../assets/images/mental-health-report-thumbnail.png";
import downloadIcon from "../../assets/images/download-icon.svg";
import { Link } from "react-router-dom";

const Index = () => {
  const ALL_TAGS = ["Nøkkelord èn", "Nøkkelord to", "Nøkkelord 3"];

  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <ContentWrapper>
          <ContentRow>
            <ContentCol
              xs={{ span: 12, order: 2 }}
              md={{ span: 7, order: 1 }}
              className="my-auto"
            >
              <Title className="below-picture">Mental helse</Title>
              <Text>
                Hvordan spise smart slik at hjernen fungerer godt og følelsene
                er i balanse?
                <br />
                <br />I samarbeid med den internasjonalt anerkjente anerkjente
                forskeren Dr. Felice Jacka har vi laget verktøy for hvordan
                nudge til mentalt sunne matvalg. Felice Jacka er Professor i
                psykiatri og direktør for Food & Mood Centre ved Deakin
                University i Australia.
              </Text>
            </ContentCol>
            <ContentCol xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
              <Thumbnail className="hero" src={mentalHealthTopThumbnail} />
            </ContentCol>
          </ContentRow>
        </ContentWrapper>
      </Container>
      <Container className="focus">
        <ContentWrapper>
          <ContentRow>
            <ContentCol md={4}>
              <Thumbnail className="hero" src={feliceJackaThumbnail} />
            </ContentCol>
            <ContentCol md={8} className="my-auto">
              <Title className="light below-picture">Dr. Felice Jacka</Title>
              <Text className="light">
                Felice Jacka er Professor i psykiatri og direktør for Food &
                Mood Centre ved Deakin University i Australia. Hun tok
                initiativet til og har ledet utviklingen av det innovative
                forskningsfeltet mat og mental helse. Hun står blant annet bak
                de første studiene av hvordan kosthold påvirker depresjon og
                hvordan endringer i kosthold kan forbedre mental helse. <br />
                <br />
                Hun har også gjort studier av sammenhengene mellom mat og mental
                helse gjennom livsløpet, og studert hvordan kosthold påvirker
                mentale funksjoner via hjernen og tarmen. Hun har skrevet mer
                enn 200 vitenskapelige artikler og utgitt to bøker. Hun er
                rangert som blant 0.1% mest publiserende forskerne
                internasjonalt. Hun leder også International Society for
                Nutritional Psychiatry Research (ISNPR) og Australian Alliance
                for the Prevention of Mental Disorders.
              </Text>
            </ContentCol>
          </ContentRow>
        </ContentWrapper>
      </Container>
      {false && (
        <Container>
          <ContentWrapper>
            <ContentRow>
              <ContentCol md={8} className="my-auto">
                <Title>Få tilgang på rapporten</Title>
                <Text>
                  Rapporten vår oppsummerte kunnskapen om hvordan kosthold
                  påvirker mental helse, og hvilke nudge som kan påvirke til
                  bedre mental helse. Dette er hovedtemaene i rapporten:
                </Text>
                <TagsWrapper>
                  {ALL_TAGS.map((tag) => (
                    <Tag>{tag}</Tag>
                  ))}
                </TagsWrapper>
                <DownloadButton>
                  <DownloadIcon src={downloadIcon} /> Last ned rapporten
                </DownloadButton>
              </ContentCol>
              <ContentCol md={4}>
                <Thumbnail src={reportThumbnail} />
              </ContentCol>
            </ContentRow>
          </ContentWrapper>
        </Container>
      )}
      <Container>
        <ContentWrapper className="align-center">
          <Title>Interessert i å vite mer?</Title>
          <Text>
            Få tilsendt et uforpliktende tilbud om nudge til din virksomhet.
          </Text>
          <Link to="mailto:knut.ivar@klimapsykologene.no">
            <Button>Få tilbud om nudge</Button>
          </Link>
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
