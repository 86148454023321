import {
  Container,
  ContentWrapper,
  ItemContainer,
  ItemTitle,
  ListCol,
  ListRow,
  ListWrapper,
  ProjectCol,
  ProjectDescription,
  ProjectThumbnail,
  ProjectTitle,
  ProjectWrapper,
  ProjectsRow,
  ProjectsWrapper,
  Section,
  Text,
  Thumbnail,
  Title,
  ContentCol,
  ContentRow,
  ProjectsView,
  CategoryWrapper,
  BoxWrapper,
  BoxTitle,
  Button,
  Subtitle,
  ContactWrapper,
} from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import { Link, useLocation } from "react-router-dom";
import projectsView from "../../assets/images/projects-list.jpeg";
import frontpageThumbnail from "../../assets/images/frontpage-thumbnail.jpg";
import thumbnail from "../../assets/images/thumbnail.svg";
import knutIvarThumbnail from "../../assets/images/photo-knutivar.svg";
import perEspenThumbnail from "../../assets/images/photo-perespen.svg";
import { useEffect, useRef } from "react";

const Index = () => {
  const scrollRef = useRef(null);
  const location = useLocation();

  const NAV_ITEMS = [
    {
      title: "Klima",
      path: "/klimanudge",
    },
    {
      title: "Bærekraft",
      path: "/bærekraftnudge",
    },
    {
      title: "Sunnhet",
      path: "/sunnhetsnudge",
    },
    {
      title: "Mental helse",
      path: "/mental-helse-nudge",
    },
  ];

  const ALL_PROJECTS = [
    {
      thumbnail: thumbnail,
      title: "Prosjekt 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae arcu et sapien scelerisque luctus. Suspendisse consequat, sem id dignissim tristique.",
    },
    {
      thumbnail: thumbnail,
      title: "Prosjekt 2",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae arcu et sapien scelerisque luctus. Suspendisse consequat, sem id dignissim tristique.",
    },
    {
      thumbnail: thumbnail,
      title: "Prosjekt 3",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae arcu et sapien scelerisque luctus. Suspendisse consequat, sem id dignissim tristique.",
    },
  ];

  useEffect(() => {
    if (location?.hash === "#kontakt") {
      scrollRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [location, scrollRef]);

  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <ContentWrapper>
          <ContentRow>
            <ContentCol
              className="my-auto"
              xs={{ span: 12, order: 2 }}
              md={{ span: 7, order: 1 }}
            >
              <Title className="below-picture">Hva er nudge?</Title>
              <Text>
                Nudge påvirker til positive valg i beslutningens øyeblikk. Nudge
                endrer beslutningssituasjonen slik at det blir lett å velge
                riktig. Nudge påvirker ubevisst uten at vi tenker oss om, og
                skaper gevinster for den enkelte og samfunnet. Norske ord for
                nudge er påvirke, stimulere, fremme eller dulte. <br />
                <br />
                Nudge er basert på psykologisk kunnskap om hvordan hjernen
                fatter beslutninger. Forskning viser at nudge kan påvirke mange
                til å velge litt bedre hver dag.
              </Text>
            </ContentCol>
            <ContentCol
              className="my-auto"
              xs={{ span: 12, order: 1 }}
              md={{ span: 5, order: 2 }}
            >
              <Thumbnail className="hero" src={frontpageThumbnail} />
            </ContentCol>
          </ContentRow>
        </ContentWrapper>
      </Container>
      <Container>
        <ContentWrapper>
          <ListWrapper>
            <ListRow>
              {NAV_ITEMS.map((item) => (
                <ListCol md={6}>
                  <Link to={item.path}>
                    <ItemContainer>
                      <ItemTitle>{item.title}</ItemTitle>
                    </ItemContainer>
                  </Link>
                </ListCol>
              ))}
            </ListRow>
          </ListWrapper>
        </ContentWrapper>
      </Container>
      <Container>
        <ContentWrapper>
          <Title>Våre prosjekter</Title>
          <Text>
            Kontakt oss for å få vite mer om prosjektene vi har gjennomført.{" "}
          </Text>
          <ProjectsWrapper>
            <ProjectsView src={projectsView} />
            {false && (
              <ProjectsRow>
                {ALL_PROJECTS.map((project) => (
                  <ProjectCol md={4}>
                    <ProjectWrapper>
                      <ProjectThumbnail src={thumbnail} />
                      <ProjectTitle>{project.title}</ProjectTitle>
                      <ProjectDescription>
                        {project.description}
                      </ProjectDescription>
                    </ProjectWrapper>
                  </ProjectCol>
                ))}
              </ProjectsRow>
            )}
          </ProjectsWrapper>
        </ContentWrapper>
      </Container>
      <Container>
        <ContentWrapper>
          <Title>Verktøy</Title>
          <Text>
            Nudge påvirker hjernens raske og impulsive valg. Nudge virker best
            når vi ikke tenker oss om. Effektive nudge skaper godt
            førsteinntrykk, fokuserer de beste alternativene, utløser positive
            følelser, minner oss om viktige mål, og informerer om konsekvensene
            av valgene.
            <br />
            <br />
            Våre verktøy for design og implementering av effektive nudge:
          </Text>
          <BoxWrapper>
            <ContentRow>
              <ContentCol md={6}>
                <CategoryWrapper>
                  <BoxTitle>Nudgeanalyse</BoxTitle>
                  <BoxTitle>Nudgeinnovasjon</BoxTitle>
                </CategoryWrapper>
              </ContentCol>
              <ContentCol md={6}>
                <CategoryWrapper>
                  <BoxTitle>Testing og måling</BoxTitle>
                  <BoxTitle>Kompetanseprogram</BoxTitle>
                </CategoryWrapper>
              </ContentCol>
              <ContentCol md={6}>
                <CategoryWrapper>
                  <BoxTitle>Bærekraftsstrategi</BoxTitle>
                  <BoxTitle>Implementering</BoxTitle>
                </CategoryWrapper>
              </ContentCol>
              <ContentCol md={6}>
                <CategoryWrapper>
                  <BoxTitle>Klimarapportering</BoxTitle>
                  <BoxTitle>Kommunikasjon</BoxTitle>
                </CategoryWrapper>
              </ContentCol>
            </ContentRow>
          </BoxWrapper>
          <Text>
            Vi starter med å kartlegge hva folk velger i dag og hvordan de
            påvirkes i valgets øyeblikk. Vi utvikler og tester de beste nudgene
            og lager en plan for hvordan de skal iverksettes. Vi lager kurs og
            kompetanseutvikling for å dele kunnskap og bruke verktøyene. Nudge
            kan forsterke virksomhetens bærekraft, og vi dokumenterer og
            kommunisere effekten på virksomhetens klimaavtrykk.
          </Text>
        </ContentWrapper>
      </Container>
      <Container ref={scrollRef} id="kontakt" className="focus">
        <ContentWrapper className="align-center">
          <Title>Kom i kontakt med oss</Title>
          <ContactWrapper>
            <ContentRow className="version-small">
              <ContentCol className="contact" md={6}>
                <Thumbnail className="hero" src={knutIvarThumbnail} />
                <Subtitle>Dr. Knut Ivar Karevold</Subtitle>
                <Text>
                  Førsteamanensis Bærekraftslaboratoriet, Universitetet i Oslo.
                </Text>
                <Link to="mailto:knut.ivar@klimapsykologene.no">
                  <Button className="contact">Send meg e-post</Button>
                </Link>
              </ContentCol>
              <ContentCol className="contact" md={6}>
                <Thumbnail className="hero" src={perEspenThumbnail} />
                <Subtitle>Dr. Per Espen Stoknes</Subtitle>
                <Text>
                  Direktør Senter for Grønn Vekst, Handelshøyskolen BI.
                </Text>
                <Link to="mailto:per.espen@klimapsykologene.no">
                  <Button className="contact">Send meg e-post</Button>
                </Link>
              </ContentCol>
            </ContentRow>
          </ContactWrapper>
          <Text className="bottom-contact">
            Vi har 10 års erfaring med nudge. Vi tilbyr foredrag og workshops,
            kurs og verktøy, og hjelp til å komme i gang. Virksomheten er en del
            av Institutt for klimapsykologi AS. Les mer om oss her:{" "}
            <Link to="https://www.klimapsykologene.no">
              www.klimapsykologene.no
            </Link>
            .
          </Text>
        </ContentWrapper>
      </Container>
      <Container>
        <ContentWrapper className="align-center">
          <Title>Interessert i å vite mer?</Title>
          <Text>
            Få tilsendt et uforpliktende tilbud om nudge til din virksomhet.
          </Text>
          <Link to="mailto:knut.ivar@klimapsykologene.no">
            <Button>Få tilbud om nudge</Button>
          </Link>
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
