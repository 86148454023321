import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  position: relative;
  background-color: #fff;
`;

export const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  &.margin-top {
    margin-top: 175px;
  }
  &.focus {
    background: #ededed;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media (max-width: 767px) {
    &.margin-top {
      margin-top: 100px;
    }
  }
`;

export const ContentWrapper = styled.div`
  max-width: 1300px;
  position: relative;
  margin: 0 auto;
  padding: 0px 15px;
  &.align-center {
    text-align: center;
  }
`;

export const ContentRow = styled(Row)``;

export const ContentCol = styled(Col)`
  @media (max-width: 767px) {
    &.contact {
      &:last-of-type {
        margin-top: 30px !important;
      }
    }
  }
`;

export const Title = styled.h1`
  color: #aa7e56;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 48px;
  @media (max-width: 767px) {
    font-size: 36px;
    &.below-picture {
      margin-top: 25px;
    }
  }
`;

export const Text = styled.p`
  margin-top: 15px;
  line-height: 2rem;
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  &.bottom-contact {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 40px;
  }
  a {
    color: #5a9572;
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  padding: 10px;
  &.hero {
    border: 5px solid #fff;
    background-color: #fff;
    box-shadow: 0px 0px 20px #dcdcdc;
  }
`;

export const ListWrapper = styled.div``;

export const ListRow = styled(Row)``;

export const ListCol = styled(Col)`
  margin-bottom: 25px;
  a {
    text-decoration: none;
  }
`;

export const ItemContainer = styled.div`
  padding: 30px;
  text-align: center;
  background: #ededed;
`;

export const ItemTitle = styled.h3`
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
`;

export const BottomContentWrapper = styled.div``;

export const ProjectsWrapper = styled.div`
  margin-top: 50px;
`;

export const ProjectsRow = styled(Row)``;

export const ProjectCol = styled(Col)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const ProjectWrapper = styled.div`
  background-color: #ededed;
  padding: 20px;
`;

export const ProjectThumbnail = styled.img`
  width: 100%;
  max-height: 300px;
`;

export const ProjectTitle = styled.h3`
  color: #569daa;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
`;

export const ProjectDescription = styled.p`
  margin-top: 15px;
  line-height: 2rem;
  color: grey;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
`;

export const ProjectsView = styled.img`
  width: 100%;
`;

export const BoxWrapper = styled.div`
  margin-top: 15px;
`;

export const CategoryWrapper = styled.div`
  background-color: #ededed;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const BoxTitle = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: grey;
  font-weight: 600;
  margin-top: 5px;
  text-transform: uppercase;
`;

export const Button = styled.button`
  background-color: #5a9572;
  font-family: "Poppins", sans-serif;
  border: none;
  color: #fff;
  padding: 12.5px 15px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  &.contact {
    margin-top: 0px;
  }
`;

export const Subtitle = styled.h3`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: grey;
  margin-top: 25px;
`;

export const ContactWrapper = styled.div`
  max-width: 750px;
  margin: 0 auto;
  margin-top: 50px;
`;
