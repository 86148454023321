import {
  LogoLink,
  Navigation,
  NavigationBar,
  NavigationBrand,
  NavigationCollapsed,
  NavigationContainer,
  NavigationLink,
  NavigationToggle,
} from "./styled";
import { ReactComponent as Logo } from "../../assets/images/logo-light.svg";
import { ReactComponent as Menu } from "../../assets/images/menu.svg";

const Index = () => {
  const clickContact = () => {
    window.location.href = "/#kontakt";
  };

  return (
    <NavigationBar expand="md" collapseOnSelect>
      <NavigationContainer>
        <LogoLink to="/">
          <NavigationBrand>
            <Logo />
          </NavigationBrand>
        </LogoLink>
        <NavigationToggle id="navToggle" aria-controls="responsiveNav">
          <Menu />
        </NavigationToggle>
        <NavigationCollapsed id="responsiveNav">
          <Navigation className="ms-auto">
            <NavigationLink to="/">Forside</NavigationLink>
            <NavigationLink to="/klimanudge">Klima</NavigationLink>
            <NavigationLink to="/bærekraftnudge">Bærekraft</NavigationLink>
            <NavigationLink to="/sunnhetsnudge">Sunnhet</NavigationLink>
            <NavigationLink to="/mental-helse-nudge">
              Mental helse
            </NavigationLink>
            <NavigationLink onClick={clickContact}>Kontakt oss</NavigationLink>
          </Navigation>
        </NavigationCollapsed>
      </NavigationContainer>
    </NavigationBar>
  );
};

export default Index;
