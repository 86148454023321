import {
  Container,
  ContentCol,
  ContentContainer,
  ContentRow,
  ContentWrapper,
  Section,
  Text,
  Thumbnail,
  Title,
} from "./styled";
import Navigation from "../navigation";
import Footer from "../footer";
import thumbnail from "../../assets/images/thumbnail.svg";

const Index = () => {
  return (
    <Section>
      <Navigation />
      <Container className="margin-top">
        <ContentWrapper>
          <ContentRow>
            <ContentCol md={8} className="my-auto">
              <Title>Hvordan hjernen tolker nudge?</Title>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                dictum, orci sit amet dictum posuere, metus quam dapibus nibh,
                at maximus eros erat a nibh. Sed viverra enim eu orci tristique
                faucibus. Pellentesque sagittis, tellus at sodales maximus,
                mauris velit malesuada orci, tempus ultricies tellus lacus ut
                urna. Nullam suscipit vestibulum eros, ut elementum dolor
                facilisis ac. Cras a risus vitae justo luctus dignissim in ut
                dolor. Suspendisse potenti.
                <br />
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                dictum, orci sit amet dictum posuere, metus quam dapibus nibh,
                at maximus eros erat a nibh. Sed viverra enim eu orci tristique
                faucibus. Pellentesque sagittis, tellus at sodales maximus,
                mauris velit malesuada orci, tempus ultricies tellus lacus ut
                urna. Nullam suscipit vestibulum eros, ut elementum dolor
                facilisis ac.
              </Text>
            </ContentCol>
            <ContentCol md={4}>
              <Thumbnail src={thumbnail} />
            </ContentCol>
          </ContentRow>
        </ContentWrapper>
      </Container>
      <Container>
        <ContentWrapper>
          <ContentRow>
            <ContentCol md={4}>
              <Thumbnail src={thumbnail} />
            </ContentCol>
            <ContentCol md={8} className="my-auto">
              <Title>Hvilke nudge påvirker sterkest?</Title>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                dictum, orci sit amet dictum posuere, metus quam dapibus nibh,
                at maximus eros erat a nibh. Sed viverra enim eu orci tristique
                faucibus. Pellentesque sagittis, tellus at sodales maximus,
                mauris velit malesuada orci, tempus ultricies tellus lacus ut
                urna. Nullam suscipit vestibulum eros, ut elementum dolor
                facilisis ac. Cras a risus vitae justo luctus dignissim in ut
                dolor. Suspendisse potenti.
                <br />
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                dictum, orci sit amet dictum posuere, metus quam dapibus nibh,
                at maximus eros erat a nibh. Sed viverra enim eu orci tristique
                faucibus. Pellentesque sagittis, tellus at sodales maximus,
                mauris velit malesuada orci, tempus ultricies tellus lacus ut
                urna. Nullam suscipit vestibulum eros, ut elementum dolor
                facilisis ac.
              </Text>
            </ContentCol>
          </ContentRow>
        </ContentWrapper>
      </Container>
      <Container>
        <ContentWrapper>
          <ContentRow>
            <ContentCol md={8} className="my-auto">
              <Title>Hvorfor nudge?</Title>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                dictum, orci sit amet dictum posuere, metus quam dapibus nibh,
                at maximus eros erat a nibh. Sed viverra enim eu orci tristique
                faucibus. Pellentesque sagittis, tellus at sodales maximus,
                mauris velit malesuada orci, tempus ultricies tellus lacus ut
                urna. Nullam suscipit vestibulum eros, ut elementum dolor
                facilisis ac. Cras a risus vitae justo luctus dignissim in ut
                dolor. Suspendisse potenti.
                <br />
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                dictum, orci sit amet dictum posuere, metus quam dapibus nibh,
                at maximus eros erat a nibh. Sed viverra enim eu orci tristique
                faucibus. Pellentesque sagittis, tellus at sodales maximus,
                mauris velit malesuada orci, tempus ultricies tellus lacus ut
                urna. Nullam suscipit vestibulum eros, ut elementum dolor
                facilisis ac.
              </Text>
            </ContentCol>
            <ContentCol md={4}>
              <Thumbnail src={thumbnail} />
            </ContentCol>
          </ContentRow>
        </ContentWrapper>
      </Container>
      <Footer />
    </Section>
  );
};

export default Index;
